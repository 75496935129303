
<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Rating Type</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Rating Type</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="RatingType">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'RatingTypeList'}"><i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-6 required">
                  <label class="control-label">Name</label>
                  <input type="text" id="name" name="name" v-bind:class="{'has-error' : errors.has('name')}" v-validate="'required'" v-model="value.name" class="form-control name" placeholder="Name">
                  <div class="help text-danger" v-show="errors.has('name')">
                    {{ errors.first("name") }}
                  </div>
                </div>
                <div class="form-group col-6 ">
                  <label class="control-label">Description</label>
                  <input type="text" id="description" name="description" v-bind:class="{'has-error' : errors.has('description')}" v-model="value.description" class="form-control description" placeholder="Description">
                  <div class="help text-danger" v-show="errors.has('description')">
                    {{ errors.first("description") }}
                  </div>
                </div>
                <div class="form-group col-12 required">
                  <label class="control-label">Equation</label>
                  <input type="text" id="equation" name="equation" v-bind:class="{'has-error' : errors.has('equation')}" v-validate="'required'" v-model="value.equation" class="form-control equation" placeholder="Equation">
                  <div class="help text-danger" v-show="errors.has('equation')">
                    {{ errors.first("equation") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Order</label>
                  <input type="text" id="order" name="order" v-bind:class="{'has-error' : errors.has('order')}" v-validate="'required'" v-model="value.order" class="form-control order" placeholder="Order">
                  <div class="help text-danger" v-show="errors.has('order')">
                    {{ errors.first("order") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">App-Id</label>
                  <select id="app_id" name="app_id" v-bind:class="{'has-error' : errors.has('app_id')}" v-validate="'required'" v-model="value.app_id" class="form-control">
                    <option v-for="application in allApplications" :value="application.app_id" :key="application.app_id">{{ application.name }}</option>
                  </select>
                  <div class="help text-danger" v-show="errors.has('app_id')">
                    {{ errors.first("app_id") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CBSProductRatingApi, CBSCommonApi } from "@/services/api";

export default {
  name: "RatingTypeForm",
  components: {
  },
  computed: {
    ...mapGetters(["profile"])
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
    value: {},
    allApplications: [],
    pre: {}
  }),
  methods: {
    handleSubmission () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true
          if (this.is_edit) {
            this.value.updated_by = this.profile.id
            CBSProductRatingApi.updateRatingType(this.params.id, this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: "RatingTypeList" })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$toaster.error(error.data);
              })
          } else {
            this.value.organization_ref_id = this.profile.organization_ref_id
            this.value.user_ref_id = this.profile.user_ref_id
            this.value.role_ref_id = this.profile.role_ref_id
            this.value.created_by = this.profile.id
            CBSProductRatingApi.storeRatingType(this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'RatingTypeList' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.data)
              })
          }
        }
      });
    },
    getDetail: function (id) {
      CBSProductRatingApi.showRatingType(id)
        .then((item) => {
          this.value = item
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    getApplications: function () {
      CBSCommonApi.getAllApplications().then((response) => {
        this.allApplications = response
      });
    },
    async initialize () {
      this.is_loading = true
      this.params = this.$route.params

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        this.getDetail(this.params.id)
      }
      this.getApplications()
      this.is_loading = false

    }
  },
  mounted () {
    this.initialize()
  }
}
</script>
